<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('commission.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-row>
      <v-col cols="6">
        {{ $t('commission.full') }}
      </v-col>
      <v-col cols="6">
        {{ $n(detail.full_commission, 'currency', 'id-ID') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('commission.adminFee') }}
      </v-col>
      <v-col cols="6">
        {{ $n(detail.beliruma_commission, 'currency', 'id-ID') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('commission.agent') }}
      </v-col>
      <v-col cols="6">
        {{ $n(detail.agent_commission, 'currency', 'id-ID') }}
      </v-col>
    </v-row>
    <v-row class="justify-center mt-6 mb-4" v-if="permissions.includes('EDIT')">
      <v-btn type="button" color="success" @click="openCommissionForm">
        {{
          !detail.payment_photos || detail.payment_photos.length === 0
            ? $t('commission.liquidate')
            : $t('commission.seeProof')
        }}
      </v-btn>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    openCommissionForm() {
      this.$modal.show('modal-commission', {
        permissions: this.permissions,
        commission: this.detail,
      });
    },
  },
};
</script>
